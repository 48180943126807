


//import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby";
import { slide as Menu } from 'react-burger-menu'

//import MobileMenu from "./MobileMenu"
import LogoSVG from "../../static/logos/full-logo.svg";
import WhiteLogoSVG from "../../static/logos/white-full-logo.svg";
//import MenuSVG from "../images/icons/menu-icon.svg";

export const Header = ({ layout }) => {

	return (
		<header className={layout === "offers" ? "purple" : ""}>
			<div className="container--header">
				{layout === "offers" &&
					<div className="header__logo">
						<div style={{}}>
							<img className="" alt={""} src={WhiteLogoSVG} />
						</div>
					</div>}
				{layout !== "offers" && <><Link to="/" className="header__logo">
					<div style={{}}>
						<img className="" alt={""} src={LogoSVG} />
					</div>
				</Link><div className="hide-for-medium">

						<Menu right className={"hide-for-large"} disableAutoFocus >
							<a href="/benefits" className="link mobileMenuItem" >Benefits </a>
							<a href="/howitworks" className="link mobileMenuItem" >How it works </a>
							<a href="/pricing" className="link mobileMenuItem" >Pricing </a>
							<a href="/about" className="link mobileMenuItem" >About </a>
							<a href="/articles" className="link mobileMenuItem" >Articles </a>
							<a className="button mobileMenuItem" href="/getintouch">Get in touch</a>
						</Menu>
					</div>
					<div className="header__content show-for-medium ">
						<ul className="header__menu">
							<li className="header__menuItem"><a href="/benefits" className="link" >Benefits </a></li>
							<li className="header__menuItem"><a href="/howitworks" className="link" >How it works </a></li>
							<li className="header__menuItem"><a href="/pricing" className="link" >Pricing </a></li>
							<li className="header__menuItem"><a href="/about" className="link" >About </a></li>
							<li className="header__menuItem"><a href="/articles" className="link" >Articles </a></li>
							<li className="header__menuButton"><a className="button" href="/getintouch">Get in touch</a></li>
						</ul>
					</div>
				</>}
			</div>
		</header>
	)
}

Header.propTypes = {
}

export default Header
