
import PropTypes from "prop-types"
import React from "react"

import LogoWhite from "../components/LogoWhite"

export const Footer = ({ layout }) => {

	return (<>
		{layout !== "offers" && <footer>
			<div className="container--footer">
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell large-8">
							<ul className="footer__menu">
								<li className="footer__menuItem"><a href="/" className="FooterLink">Home</a> </li>
								<li className="footer__menuItem"><a href="/howitworks" className="FooterLink">How it works</a> </li>
								<li className="footer__menuItem"><a href="/benefits" className="FooterLink">Benefits</a> </li>
								<li className="footer__menuItem"><a href="/pricing" className="FooterLink">Pricing</a> </li>
								<li className="footer__menuItem"><a href="/about" className="FooterLink">About</a> </li>
								<li className="footer__menuItem"><a href="/articles" className="FooterLink">Articles</a> </li>
								<li className="footer__menuItem"><a href="/getintouch" className="FooterLink">Get in touch</a> </li>

							</ul>
							<div className="footer__copywrite show-for-large">
								&#169; 2024 Inhabit Place Ltd. All rights reserved  |  <a href="/privacy">Privacy policy </a> |  <a href="/termsandconditions">Terms & conditions</a>
							</div>
						</div>
						<div className="cell large-4">
							<div className="footer__link-wrapper" >
								<div className="footer__logo">
									<LogoWhite />
								</div>
								<div className="footer__copywrite">
									An easier way to monitor public space
								</div>
								<div className="footer__icons">
									<ul className="footer__icons--wrapper">
										<li className="footer__socialIcon"><a className="icon--linkedin" href="https://www.linkedin.com/company/inhabit-place/" target="_blank" rel="noopener noreferrer"><span className="show-for-sr">LinkekIn</span></a></li>
										<li className="footer__socialIcon"><a className="icon--instagram" href="https://www.instagram.com/inhabitplace/" target="_blank" rel="noopener noreferrer"><span className="show-for-sr">Instagram</span></a></li>
										{/*<li className="footer__socialIcon facebook"><a className="icon--facebook" href="/" target="_blank" rel="noopener noreferrer"><span className="show-for-sr">Facebook</span></a></li> <li className="footer__socialIcon twitter"><a className="icon--twitter" href="/" target="_blank" rel="noopener noreferrer"><span className="show-for-sr">Twitter</span></a></li>*/}
									</ul>
								</div>
							</div>
						</div>
						<div className="cell large-6">
							<div className="footer__copywrite hide-for-large">
								&#169; 2024 Inhabit Place Ltd. All rights reserved  |  <a href="/privacy">Privacy policy </a> |  <a href="/termsandconditions">Terms & conditions</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>}</>)
}


Footer.propTypes = {
}
export default Footer


