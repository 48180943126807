

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Transition from "../components/Transition"
import Header from "../components/Header"
import Footer from "../components/Footer"
import "fontsource-montserrat/800.css"
//import "./Layout.css"


const Layout = ({ children, location, pageContext }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
		  site {
			siteMetadata {
			  title
			}
		  }
		}
	  `)

	const layout = pageContext.layout || 'default'
	return (
		<>
			<Header layout={layout} />

			<main>
				<Transition location={location}>
					{children}
				</Transition>
			</main>

			<Footer layout={layout} />

		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
