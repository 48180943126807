/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */



// global site stylesheet
import "./src/scss/app.scss"


// page transition timing
const transitionDelay = 400


// Fix scroll jump on page transition
export function shouldUpdateScroll({ routerProps: { location }, getSavedScrollPosition }) {
	if (location.action === 'PUSH') {
		window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
	} else {
		const savedPosition = getSavedScrollPosition(location)
		window.setTimeout(
			() => window.scrollTo(...(savedPosition || [0, 0])),
			transitionDelay
		)
	}
	return false
}


// fire on first page load
export function onInitialClientRender() {

}


// fire on page change - delay for page transition so elements have changed in the DOM
export function onRouteUpdate({ location, prevLocation }) {
	setTimeout(function () {
		
	}, transitionDelay + 100)
}
